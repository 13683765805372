import React from "react";
import PageNote from "../pageNote.jsx/pageNote";
import "./access.css";

export default function Access() {
  return (
    <div className="access">
      <div className="venueMap">
        <iframe
          className="gmap_iframe"
          width="auto"
          height="400px"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.4820311372337!2d78.47694426058308!3d13.631439116722165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb2677c83886ad7%3A0xad73159e2bddda33!2sMadanapalle%20institute%20of%20technology%20and%20Science!5e0!3m2!1sen!2sin!4v1700217909159!5m2!1sen!2sin"
        ></iframe>
      </div>
      <h1>Travel</h1>
      <p>There is a different access method depending on where you arrive.</p>
      <h2>Kempegowda International Airport Bengaluru</h2>
      <p>By Cab</p>
      <p>Kempegowda International Airport to MITS, Angallu</p>
      <p>123 km (3hr Journey)</p>

      <h2>Madanapalle Road Railway station</h2>
      <p>By Cab</p>
      <p>Madanapalle Road Railway station to MITS, Angallu</p>
      <p>9.5 km (25min Journey)</p>
    </div>
  );
}
