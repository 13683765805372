import React from 'react';
import './ConfirmationModal.css'
function ConfirmationModal({ s, que, a1, a2 , isOpen, onClose, onConfirm, }) {
  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <p>It is required to make payment before registering for the conference.</p><br/>
          <p>Have you made the payment?</p>
          <button onClick={onClose}>No</button>
          <button  onClick={onConfirm}>Yes</button> 
        </div>
      </div>
    )
  );
}

export default ConfirmationModal;
