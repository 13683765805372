import React, { useState } from "react";
import "./payment.css";
import { Typography } from "@mui/material";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import StudentProof from "../../components/ConfirmationModal/studentproof";
import IeeeMembershipProof from "../../components/ConfirmationModal/ieeeMembershipProof";

function Payment() {
  const [isstudentModalOpen, setIsstudentModalOpen] = useState(false);
  const handlestudentConfirm = () => {
    setIsstudentModalOpen(false);
  };
  const [isIEEEModalOpen, setisIEEEModalOpen] = useState(false);
  const handleIEEEConfirm = () => {
    setisIEEEModalOpen(false);
  };

  const [isStudent, setisStudent] = useState(false);
  const [mop, setmop] = useState(false);
  const [hasadditionalPaper, sethasadditionalPaper] = useState(false);

  const [ieeeMem, setieeeMem] = useState("NIM");
  const [Indian, setIndian] = useState(null);
  const [p1, setp1] = useState(null);
  const [p2, setp2] = useState(null);
  const [coauthCount, setcoauthCount] = useState(null);

  const [inr, setINR] = useState(0);
  const [usd, setUSD] = useState(0);

  const handleStudent = (v) => {
    // console.log("student : ", v);
    setisStudent(v);
    if(v===true){
      sethasadditionalPaper(false);
    }
  };

  const handleIEEEMembership = (v) => {
    // console.log("IEEE : ", v);
    setieeeMem(v);
  };
  const handleIndian = (v) => {
    // console.log("Inidian : ", v);
    setIndian(v);
  };
  const handleMOP = (v) => {
    // console.log("MOP : ", v);
    setmop(v);
  };
  const handleP1Pages = (v) => {
    // console.log("P1pages : ", v);
    setp1(v);
  };
  const handleAdditionalPaper = (v) => {
    // console.log("ADDN paper : ", v);
    sethasadditionalPaper(v);
  };
  const handleP2Pages = (v) => {
    // console.log("P2Pages : ", v);
    setp2(v);
  };
  const handleCoauthorsCount = (v) => {
    // console.log("Coauth count : ", v);
    setcoauthCount(v);
  };

  const calculateFinalFee = (e) => {
    const checkIndianity = () => {
      var ele = document.getElementsByName("Indian");
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].checked) {
          return ele[i].value;
        }
      }
    };
    let ieeeMembership = document.getElementById("ieeemembership").value;
    let isIndian = checkIndianity();
    let p1Pages = document.getElementById("p1pages").value;
    let p2Pages = hasadditionalPaper
      ? document.getElementById("p2pages").value
      : 6;
    let coauthorsCount = !mop
      ? document.getElementById("coauthorscount").value
      : 0;

    if (
      isIndian === undefined ||
      ieeeMembership === "select" ||
      p1Pages === "select" ||
      coauthorsCount === "select"
    ) {
      console.log("SELECT ALL");
      alert("All Fields are required to estimate your registration fee.");
    } else {
      let i = 0;
      let u = 0;
      if (isStudent) {
        if (ieeeMembership === "NIM") {
          i = 8000;
          u = 150;
        } else if (ieeeMembership === "IM") {
          i = 7000;
          u = 125;
        } else if (ieeeMembership === "LIM") {
          i = 6000;
          u = 125;
        }
      } else {
        if (ieeeMembership === "NIM") {
          i = 12000;
          u = 300;
        } else if (ieeeMembership === "IM") {
          i = 10000;
          u = 250;
        } else if (ieeeMembership === "LIM") {
          i = 6000;
          u = 125;
        }
      }

      if (mop) {
        i = i - 3000;
        u = u - 50;
      }

      if (hasadditionalPaper) {
        i = i + 2000;
        u = u + 25;
      }

      i = i + 500 * (p1Pages - 6);
      u = u + 10 * (p1Pages - 6);

      i = i + 500 * (p2Pages - 6);
      u = u + 10 * (p2Pages - 6);

      i = i + coauthorsCount * 4000;
      u = u + coauthorsCount * 100;

      console.log("student : ", isStudent);
      console.log("IEEE : ", ieeeMembership);
      console.log("Indian : ", isIndian);
      console.log("MOP : ", mop);
      console.log("P1pages : ", p1Pages);
      console.log("ADDN paper : ", hasadditionalPaper);
      console.log("P2Pages : ", p2Pages);
      console.log("Coauth count : ", coauthorsCount);

      console.log(i);
      console.log(u);

      setINR(i);
      setUSD(u);
      handleIEEEMembership(ieeeMembership);
      handleIndian(isIndian);
      handleP1Pages(p1Pages);
      handleP2Pages(p2Pages);
      handleCoauthorsCount(coauthorsCount);
      // displyFinalFee(i,u);

      console.log("---------------------");
    }
    e.preventDefault();
  };

  // const displyFinalFee = (inru, u) => {
  //   alert(inru, u);
  // };

  return (
    <div className="paymentBody">
      <h1> Payment</h1>
      <hr />

      <form>
        <div className="feeField">
          <Typography variant="p" className="feeQue">
            Are you a student &nbsp; &nbsp; :
          </Typography>
          <label>
            <input
              required
              onClick={(e) => {
                handleStudent(true);
                setIsstudentModalOpen(true);
              }}
              type="radio"
              name="student"
              value={true}
            />
            Yes
          </label>
          <label>
            <input
              required
              onClick={(e) => {
                handleStudent(false);
              }}
              type="radio"
              name="student"
              value={false}
            />
            No
          </label>
          <StudentProof
            isOpen={isstudentModalOpen}
            onClose={() => setIsstudentModalOpen(false)}
            onConfirm={handlestudentConfirm}
          />
        </div>
        <hr />

        <div className="feeField">
          <Typography variant="p" className="feeQue">
            IEEE Membership &nbsp; &nbsp; :&nbsp; &nbsp;{" "}
          </Typography>
          <select
            required
            id="ieeemembership"
            onChange={(e) => {
              if (e.target.value !== "NIM") {
                setisIEEEModalOpen(true);
              }
              // handleIEEEMembership(e.target.value);
            }}
          >
            <option selected disabled hidden>
              select
            </option>
            <option value="NIM">Non-IEEE Member</option>
            <option value="IM">IEEE Member</option>
            <option value="LIM">Lifetime-IEEE Member</option>
          </select>
          <IeeeMembershipProof
            isOpen={isIEEEModalOpen}
            onClose={() => setisIEEEModalOpen(false)}
            onConfirm={handleIEEEConfirm}
          />
        </div>
        <hr />

        <div className="feeField">
          <Typography variant="p" className="feeQue">
            Are you a Indian &nbsp; &nbsp; :
          </Typography>
          <label>
            <input
              required
              onClick={(e) => {
                // handleIndian(e.target.value);
              }}
              type="radio"
              name="Indian"
              value={true}
            />
            Yes
          </label>
          <label>
            <input
              required
              onClick={(e) => {
                // handleIndian(e.target.value);
              }}
              type="radio"
              name="Indian"
              value={false}
            />
            No
          </label>
        </div>
        <hr />

        <div className="feeField">
          <Typography variant="p" className="feeQue">
            Mode of participation &nbsp; &nbsp; :
          </Typography>
          <label>
            <input
              required
              onClick={(e) => {
                handleMOP(false);
              }}
              type="radio"
              name="mop"
              value={false}
            />
            Offline
          </label>
          <label>
            <input
              required
              onClick={(e) => {
                handleMOP(true);
              }}
              type="radio"
              name="mop"
              value={true}
            />
            Online
          </label>
        </div>
        <hr />

        <div className="feeField">
          <Typography variant="p" className="feeQue">
            Number of pages in your paper &nbsp; &nbsp; :&nbsp; &nbsp;{" "}
          </Typography>
          <select
            required
            id="p1pages"
            onChange={(e) => {
              // handleP1Pages(e.target.value);
            }}
          >
            <option selected disabled hidden>
              select
            </option>
            <option value={6}>{"<="}6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
        </div>
        <hr />

        {!isStudent ? (
          <div className="feeField">
            <Typography variant="p" className="feeQue">
              Do you have an additional paper (Maximum 1 additional paper is
              allowed for non-student author) &nbsp; &nbsp; :
            </Typography>
            <label>
              <input
                required
                onClick={(e) => {
                  handleAdditionalPaper(true);
                }}
                type="radio"
                name="addnpaper"
                value={true}
              />
              Yes
            </label>
            <label>
              <input
                required
                onClick={(e) => {
                  handleAdditionalPaper(false);
                }}
                type="radio"
                name="addnpaper"
                value={false}
              />
              No
            </label>
            <hr />
          </div>
        ) : null}

        {hasadditionalPaper ? (
          <div className="feeField">
            <Typography variant="p" className="feeQue">
              Number of pages in your additional paper &nbsp; &nbsp; :&nbsp;
              &nbsp;{" "}
            </Typography>
            <select
              required
              id="p2pages"
              onChange={(e) => {
                // handleP2Pages(e.target.value);
              }}
            >
              <option selected disabled hidden>
                select
              </option>
              <option value={6}>{"<="}6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
            </select>
            <hr />
          </div>
        ) : null}

        {!mop ? (
          <div className="feeField">
            <Typography variant="p" className="feeQue">
              Number of co-authors attending the conference(4000INR/100USD per
              each extra participant) &nbsp; :&nbsp; &nbsp;{" "}
            </Typography>
            <select
              required
              id="coauthorscount"
              onChange={(e) => {
                // handleCoauthorsCount(e.target.value);
              }}
            >
              <option selected disabled hidden>
                select
              </option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
            <hr />
          </div>
        ) : null}
        <div className="payBtnDiv">
          <button
            // type="submit"
            className="payBtn"
            onClick={(e) => {
              calculateFinalFee(e);
            }}
          >
            Calculate My Registration Fee
          </button>
        </div>
        {/* <p>{inr === 0 ? null : inr}</p>
        <p>{usd === 0 ? null : usd}</p> */}
        <br />
        <br />
        <br />
        <br />
      </form>

      {inr === 0 ? null : (
        <div className="bill">
        <hr/>
        <h3>Your Fee Breakdown</h3>
        <hr/>
          {ieeeMem === "LIM" ? (
            <div>
              <p>You are a LIM </p>
              <p>:{" "}</p>
              <p>+6000 INR / +125 USD </p>
            </div>
          ) : null}
          {isStudent && ieeeMem === "NIM" ? (
            <div>
              <p>You are a Student but a non-IEEE Member </p>
              <p>:{" "}</p>
              <p> +8000 INR / +150 USD</p>
            </div>
          ) : null}
          {isStudent && ieeeMem === "IM" ? (
            <div>
              <p>You are a Student and IEEE Member </p>
              <p>:{" "}</p>
              <p> +7000 INR / +125 USD </p>
            </div>
          ) : null}
          {!isStudent && ieeeMem === "NIM" ? (
            <div>
              <p>You are a non-Student and non-IEEE Member </p>
              <p>:{" "}</p>
              <p> +12000 INR / +300 USD</p>
            </div>
          ) : null}
          {!isStudent && ieeeMem === "IM" ? (
            <div>
              <p>You are a non-Student but a IEEE Member </p>
              <p>:{" "}</p>
              <p> +10000 INR / +250 USD</p>
            </div>
          ) : null}
          {mop ? (
            <div>
              <p>Your mode of participation is Online </p>
              <p>:{" "}</p>
              <p> -3000 INR / -50 USD </p>
            </div>
          ) : null}
          {p1 > 6 ? (
            <div>
              <p>
                Your paper has {p1 - 6} more extra pages than the usual limit(6
                pages per paper){" "}
              </p>
              <p>:{" "}</p>
              <p>
                {" "}
                +{(p1 - 6) * 500} INR / +{(p1 - 6) * 10} USD
              </p>{" "}
            </div>
          ) : null}
          {hasadditionalPaper ? (
            <div>
              <p>You have an Extra paper </p>
              <p>:{" "}</p>
              <p> +2000 INR / +25 USD</p>{" "}
            </div>
          ) : null}
          {hasadditionalPaper && p2 > 6 ? (
            <div>
              <p>
                Your Extra paper has {p2 - 6} more extra pages than the usual
                limit(6 pages per paper){" "}
              </p>
              <p>:{" "}</p>
              <p>
                {" "}
                +{(p2 - 6) * 500} INR / +{(p2 - 6) * 10} USD
              </p>{" "}
            </div>
          ) : null}
          {coauthCount > 0 ? (
            <div>
              <p>
                Fee for {coauthCount} number of Co-authors to attend the
                conference ({coauthCount}*4000INR / {coauthCount}*100USD ){" "}
              </p>
              <p>:{" "}</p>
              <p>
                {" "}
                +{coauthCount * 4000} INR / +{coauthCount * 100} USD
              </p>
            </div>
          ) : null}
          <hr />
          <div>
            <p>Total Amount you need to pay to make a valid Registration</p>
            <p>:{" "}</p>
            <p>
              {" "}
              {inr} INR / {usd} USD
            </p>
          </div>
          <hr />
        </div>
      )}
      <div className="bankDetails">
        <h2>Bank Details</h2>
        <h3>
          The details for domestic transaction(for Indians) is mentioned below.
        </h3>
        <p>( Make the payment and save the proof for further registration )</p>
        <br />
        <h4>Name : MADANAPALLE INSTITUTE OF TECHNOLOGY AND SCIENCE</h4>
        <h4>Account No: 75690200000436</h4>
        <h4>IFSC Code: BARB0VJMADA ( Fifth letter " ZERO")</h4>
        <h4>Branch Name: CTM Road, MADANPALLE</h4>
        
        <br />
      </div>
      <div className="bankDetails">
        <h3>For Foreign Transactions (Other than India) </h3>
        {/* <h4>Click <a href></a> </h4>
        <h4>1. Enter your Paper ID</h4>
        <h4>2. Choose the Currency</h4>
        <h4>3. Enter your Calculated Registration Fee</h4> */}

      </div>
    </div>
  );
}

export default Payment;
