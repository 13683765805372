import React from "react";
import k1 from "../../assets/wen-liang-hwang-k1.jpg";
import "./speakerAbstract.css";
import { useParams } from "react-router";
import { keyNote } from "./keyNote";
import { invitedSpeakers } from "./invitedSpeakers";

function SpeakerAbstract() {
  const params = useParams();
  console.log(params.name);
  const speakers = [...keyNote, ...invitedSpeakers];
  const data = speakers.find((speaker) => speaker.name === params.name);
  console.log("data=====", data);
  return (
    <div className="singleSpeaker">
      <div className="firstRow">
        <div className="profileName">
          <div className="pic">
            <img src={data.pic} />
          </div>
          {/* <div className="name">
            <h3>Wen-Liang Hwang</h3>
          </div> */}
        </div>
        <div className="bio">
          <p>
            <strong>{data.name}</strong> {data.bio}
          </p>
        </div>
      </div>
      <div>
        <h3>
          Title : <br />
          {data.title}
        </h3>
        <br />
        <h3>Abstract:</h3>
        <p>{data.abstract}</p>
      </div>
    </div>
  );
}

export default SpeakerAbstract;
