import React from "react";
import PageNote from "../pageNote.jsx/pageNote";
import { Typography } from "@mui/material";
import ICOTLViasForm from '../../assets/ICOTL_Information-for-Invitation.docx';
import "./visa.css";

export default function Visa() {
  return (
    <div className="visa">
      <h1>Visa</h1>
      <p>
        If you need visa to enter India, for participating ICOTL2023 conference,<br/>
        Please fill out the form and<br/>  mail it to Goutam Chakraborty, General Chair.<br/>
        <span className="mailid">profgoutamchakraborty@mits.ac.in</span>
        <Typography className="posterDownload">
        <a href={ICOTLViasForm} download style={{ color: "orange", "font-size":"25px"}}>
        Download the Form
        </a>{" "}
      </Typography>
      </p>
    </div>
  );
}
