import React from 'react';
import './ConfirmationModal.css'
function StudentProof({isOpen, onClose, onConfirm, }) {
  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <p>You must provide your Student proof in further registration process. If your proof is found invalid, your registration will be cancelled and there will be no refund for your payment.</p><br/>
          <button onClick={onClose}>Ok</button>
        </div>
      </div>
    )
  );
}

export default StudentProof;
