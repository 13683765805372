import React from "react";
import "./speakerCard.css";
import k1 from "../../assets/wen-liang-hwang-k1.jpg";
import { appConstants } from "../../constants/appConstants";
import { NavLink } from "react-router-dom";

function SpeakerCard(props) {
  return (
    <div className="speakers">
      <div className="keyNote">
        {props.speakers.map((speaker) => (
          <div className="speaker">
            <div className="pic">
              <img src={speaker.pic} />
            </div>
            <div className="name">
              <h3>
                <NavLink to={`/speaker/${speaker.name}`}>{speaker.name}</NavLink>
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SpeakerCard;
