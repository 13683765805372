import React from "react";
import "./program.css";
import PageNote from "../pageNote.jsx/pageNote";
import SpeakerCard from "./speakerCard";
import { keyNote } from "./keyNote";
import { invitedSpeakers } from "./invitedSpeakers";
import { Typography } from "@mui/material";
import icotl_schedule from "../../assets/ICOTL_Programme_Schedule.pdf"

export default function Program() {
  return (
    <div className="program">
      <Typography className="posterDownload">
        <a href={icotl_schedule} download className="blinking">
         Click here
        </a>{" "}
        to download the ICOTL 2023 Programme Schedule.
      </Typography><br/>
      <h2>Key Note Speakers</h2>
      <SpeakerCard speakers={keyNote} />
      <h2>Invited Speakers</h2>
      <SpeakerCard speakers={invitedSpeakers} />
    </div>
  );
}
