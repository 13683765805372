import React from "react";
import "./submission.css";
import { Typography } from "@mui/material";
import Link from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Brightness1Icon from "@mui/icons-material/Brightness1";

export default function Submission() {
 
  return (
    <div className="submission">
      <p className="title">Paper Submission</p>
      <p className="submissionNote">
        You are welcome to present your latest works on optimization techniques
        & their application in various machine learning, data analysis model
        building and other applications. Submitted papers must be in a PDF
        format and should be original, unpublished, and not under submission to
        other conferences or journals.
      </p>
      <p className="submissionNote">
        The page limit for a full-length paper is 6 pages. Additional up to 2
        pages are acceptable with extra page charge for the 7th and 8th pages.
        Please check "Registration" page for details. Papers with less than 4
        pages or more than 8 pages will be rejected without review.
      </p>
      <p className="submissionNote">
        All submissions should be in the IEEE two-column format. Author's names
        and affiliation should be kept blank because of double blind review.
        Papers should contain up to 5 keywords. Papers will be evaluated for
        originality, significance, clarity, and soundness, and will be reviewed
        by at least three independent reviewers. Please note that ithenticate
        will be used for plagiarism detection.
      </p>
      <Typography variant="h5" fontWeight={550}>
        LaTeX and Word Templates:{" "}
      </Typography>
      <p className="submissionNote">
        Please use the IEEE style files for conference proceedings as a
        template.
      </p>
      {/* <p className="submissionNote">
        Click here for more information (Please use US letter format!)
      </p> */}
      <a
        className="templateLink"
        href="https://www.ieee.org/conferences/publishing/templates.html"
        target="_blank"
        rel="noreferrer"
      >
        <p style={{ paddingBottom: 10 }}>
          Click here to download the IEEE paper template. (Please use US letter
          format!)
        </p>
      </a>
      {/* <Typography variant="h5">Camera Ready Paper Submission Format:</Typography> */}
      <Typography variant="h5" fontWeight={550}>
        Camera Ready Paper can be prepared and submitted by following steps:
      </Typography>
      <div className="steps">
        <div className="step">
          <EastIcon />
          <p>
            <strong>Step 1 : Prepare Your Final Paper. </strong>
          </p>
        </div>
        <div className="stepnote">
          <p>
            Accepted and Revision Required Paper authors are advised to make
            necessary corrections and modifications suggested by the reviewers
            in your final manuscript and upload the final papers on or before
            November 26, 2023. Papers for publication should conform to the IEEE
            format and specifications. Authors need to submit the full final
            paper (Minimum 4 pages, Maximum 6 pages, double-column US letter
            size) as PDF using the IEEE templates. Extra pages (Maximum 2)
            beyond this would require additional page length charges. Please
            check <a href="https://icotl.in/#/registration">"Registration"</a>{" "}
            for details. The IEEE paper template can be downloaded from the link
            given below.
            <br />
            <a href="http://www.ieee.org/conferences_events/conferences/publishing/templates.html">
              http://www.ieee.org/conferences_events/conferences/publishing/templates.html
            </a>
          </p>
        </div>
        <div className="step">
          <EastIcon />
          <p>
            <strong>
              Step 2 : Add Copyright/ISBN information to the first page of the
              paper.
            </strong>
          </p>
        </div>
        <div className="stepnote">
          <p>
            <List>
              <ListItem className="registrationNoteListItem">
                <ListItemIcon className="notesListItemIcon">
                  <Brightness1Icon fontSize="10px" className="brightnessIcon" />
                </ListItemIcon>
                <ListItemText>
                  Add the following copyright notice to the bottom of the first
                  page of your source document.
                  <br /> 979-8-3503-2804-2/23/$31.00 ©2023 IEEE
                </ListItemText>
              </ListItem>
              <ListItem className="registrationNoteListItem">
                <ListItemIcon className="notesListItemIcon">
                  <Brightness1Icon fontSize="10px" className="brightnessIcon" />
                </ListItemIcon>
                <ListItemText>
                  Proofread your source document thoroughly to confirm that it
                  will require no revision.
                </ListItemText>
              </ListItem>
            </List>
          </p>
        </div>
        <div className="step">
          <EastIcon />
          <p>
            <strong>Step 3 : Check Your Final Paper </strong>
          </p>
        </div>
      </div>
      <div className="stepnote">
        <p>
          Please double-check the information that you provide during the online
          submission:
          <List className="stepslist">
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">
                <Brightness1Icon fontSize="10px" className="brightnessIcon" />
              </ListItemIcon>
              <ListItemText>Title of the paper</ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">
                <Brightness1Icon fontSize="10px" className="brightnessIcon" />
              </ListItemIcon>
              <ListItemText>
                Name, affiliation, the e-mail address of all the authors
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">
                <Brightness1Icon fontSize="10px" className="brightnessIcon" />
              </ListItemIcon>
              <ListItemText>Name of the corresponding author</ListItemText>
            </ListItem>
          </List>
        </p>
      </div>
      <div className="step">
        <EastIcon />
        <p>
          <strong>Step 4 : IEEE PDF eXpress</strong>
        </p>
      </div>
      <div className="stepnote">
        <p>
          Papers must be compatible with IEEE Xplore format. Papers that do not
          fit with the format cannot be published in IEEE Xplore. To meet this
          requirement, authors should verify the files using the IEEE PDF
          eXpress system. IEEE PDF eXpress can generate compatible PDF files
          with IEEE format from the source files (Conversion Function) or verify
          that the PDF files are compatible with the IEEE Xplore format.
          <br />
          <br />
          <strong>Creating your PDF eXpress Account:</strong>
          <List>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">
                <Brightness1Icon fontSize="10px" className="brightnessIcon" />
              </ListItemIcon>
              <ListItemText>
                Log in to the IEEE PDF eXpress TM site{" "}
                <a href="https://ieee-pdf-express.org/">
                  {" "}
                  https://ieee-pdf-express.org/
                </a>
              </ListItemText>
            </ListItem>
          </List>
          First-time users should do the following:
          <List>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">1.</ListItemIcon>
              <ListItemText>
                Click{" "}
                <a href="https://ieee-pdf-express.org/account/signup">here</a>
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">2.</ListItemIcon>
              <ListItemText>
                Enter your mailid, a password and <strong>59758X</strong> for
                the Conference ID.
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">3.</ListItemIcon>
              <ListItemText>Continue</ListItemText>
            </ListItem>
          </List>
          An Online confirmation will be displayed and an email confirmation
          will be sent verifying your account setup.
          <br />
          <strong>Previous users</strong> of PDF eXpress need to follow the
          above steps, but should enter the same password that was used for
          previous conferences. Verify that your contact information is valid.
          <br />
          <strong>How To Prepare PDF Xpress Document : </strong>
          <br />
          <List>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">1.</ListItemIcon>
              <ListItemText>
                Select "Create New Title" for every full paper.
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">2.</ListItemIcon>
              <ListItemText>
                Select "Submit File for Checking or Converting".
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">3.</ListItemIcon>
              <ListItemText>
                Select "Browse" and find the file. The selected file may be a
                PDF for compatibility or file in another format to be converted
                to PDF. For more details go to the website{" "}
                <a href="https://ieee-pdf-express.org/External/UsingIEEEPDFeXpress">
                  {" "}
                  https://ieee-pdf-express.org/External/UsingIEEEPDFeXpress
                </a>
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">4.</ListItemIcon>
              <ListItemText>
                Select "Upload File". You will receive confirmation mail that
                the upload was successful.
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">5.</ListItemIcon>
              <ListItemText>
                You will receive an email with your new PDF file, PDF verified
                or converted by the IEEE PDF eXpress system. If a PDF file is
                uploaded for verification, the email will indicate whether it
                passed or failed.{" "}
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">6.</ListItemIcon>
              <ListItemText>
                You need to approve for collection in your page of the IEEE PDF
                eXpress system.
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">7.</ListItemIcon>
              <ListItemText>
                After approval collect your PDF Xpress file and upload in your
                CMT portal.{" "}
              </ListItemText>
            </ListItem>
          </List>
        </p>
      </div>
      <div className="step">
        <EastIcon />
        <p>
          <strong>Step 5 : Upload Your Final Paper. </strong>
        </p>
      </div>
      <div className="stepnote">
        <p>
          <List>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">
                <Brightness1Icon fontSize="10px" className="brightnessIcon" />
              </ListItemIcon>
              <ListItemText>
                The camera-ready paper must be in PDF Xpress format.
              </ListItemText>
            </ListItem>
            <ListItem className="registrationNoteListItem">
              <ListItemIcon className="notesListItemIcon">
                <Brightness1Icon fontSize="10px" className="brightnessIcon" />
              </ListItemIcon>
              <ListItemText>
              Upload your camera ready paper through Microsoft CMT using the 'Create Camera Ready Submission’ Option along with word file or latex file (with all source files). 
              </ListItemText>
            </ListItem>
          </List>
        </p>
      </div>
      <div className="step">
        <EastIcon />
        <p>
          <strong>Step 6 : IEEE (Copyright Submission) </strong>
        </p>
      </div>
      <div className="stepnote">
        <p>
          From your CMT author account, you will be directed to sign electronic
          copyright form in IEEE site. Please follow the instructions and upload
          pdf of signed copyright in CMT system.
        </p>
      </div>
      <br />
      <br />
      If you have any queries please feel free to contact the undermentioned.<br/>
      <strong> icotl-info@mits.ac.in</strong>
      <br />
      <br />
      Hope to see you all at the Conference.
      <p className="submissionNote1"></p>
      <a
        href="https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FICOTL2023"
        target={"_blank"}
        className="submissionBtn"
      >
        Submit Your Camera Ready Paper
      </a>
    </div>
  );
}

{
  /* 
<div className="step">
<EastIcon />
<p>
  <strong>Step 2 : </strong>Add Copyright/ISBN information to the
  first page of the paper.
</p>
</div> 

<div className="stepnote">
          <p>
            
          </p>
        </div>

        <List>
          <ListItem className="registrationNoteListItem">
            <ListItemIcon className="notesListItemIcon">
              <Brightness1Icon fontSize="10px" className="brightnessIcon" />
            </ListItemIcon>
            <ListItemText></ListItemText>
          </ListItem>
      </List>
*/
}
