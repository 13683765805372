import React from "react";
import venueBg from "./../../assets/venueBg.jpg";
import "./venue.css";
import loc1 from "./../../assets/mitsvenue1.png";
import loc2 from "./../../assets/mitsvenue2.png";
import loc3 from "./../../assets/mits_img_1.jpg";
import loc4 from "./../../assets/mits_img_2.jpg";
import loc5 from "./../../assets/mits_img_4.jpg";

export default function Venue() {
  return (
    <div>
      <div className="venueDetailsDiv">
        <div className="VenueBgImgDiv">
          <img className="venueBgImg" src={venueBg} />
          <div className="venueDetailsBody">
            <div className="venueText">
              <p className="venueHeader">
                In Person | Angallu (near Madanapalle) | 7<sup>th </sup>- 8
                <sup>th</sup> December, 2023
              </p>
              <p className="venueTitle">
                Madanapalle Institute of Technology & Science
              </p>
              <span>
                Post Box No: 14, Kadiri Road Angallu (V), Madanapalle-517325
                <br />
                Annamayya District, Andhra Pradesh, India
              </span>
              <br />
              <br />
              {/* <span>
                <h4>
                  (There will be special discount of 10% for conference
                  participants for accomodation.)
                </h4>
              </span> */}
              <br />
              <img className="locImgTitle" src={loc1} />
              <div className="locImagesDiv">
                <img className="locImages" src={loc2} />
                <img className="locImages" src={loc4} />
                <img className="locImages" src={loc3} />
                <img className="locImages" src={loc5} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="venueMap">
            <iframe
              className="gmap_iframe"
              width="auto"
              height="400px"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.4820311372337!2d78.47694426058308!3d13.631439116722165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb2677c83886ad7%3A0xad73159e2bddda33!2sMadanapalle%20institute%20of%20technology%20and%20Science!5e0!3m2!1sen!2sin!4v1700217909159!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
