import k1 from '../../assets/wen-liang-hwang-k1.jpg'
import k2 from '../../assets/robert-kozma-k2.png'
import k3 from '../../assets/sanghamitra-bandyopadhyay-k3.png'
export const keyNote = [
  {
    name: "Prof. Wen-Liang Hwang",
    pic: k1,
    bio: " received the B.S. degree in nuclear engineering from National Tsing Hua University, Taiwan, the M.S. degree in electrical engineering from the Polytechnic Institute of New Year and Ph.D. degree in computer science from New York University.  He is coauthor of the book ``Practical Time-Frequency Analysis (Academia, 1988). He was a member of the Institute of Information Science, Academia Sinica, Taiwan since 1995 until now. He is currently working in Responsible.ai. His main interests are analysis of deep networks and explainable AI.",
    title:
      "Representation and decomposition of functions in DAG-DNNs and structural network pruning",
    abstract:
      "The conclusions provided by deep neural networks (DNNs) must be carefully scrutinized to determine whether they are universal or architecture dependent. The term DAG-DNN refers to a graphical representation of a DNN in which the architecture is expressed as a direct-acyclic graph (DAG), on which arcs are associated with functions. The level of a node denotes the maximum number of hops between the input node and the node of interest. In the current study, we demonstrate that DAG-DNNs can be used to derive all functions defined on various sub-architectures of the DNN. We also demonstrate that the functions defined in a DAG-DNN can be derived via a sequence of lower-triangular matrices, each of which provides the transition of functions defined in sub-graphs up to nodes at a specified level. The lifting structure associated with lower-triangular matrices makes it possible to perform the structural pruning of a network in a systematic manner. The fact that decomposition is universally applicable to all DNNs means that network pruning could theoretically be applied to any DNN, regardless of the underlying architecture. We demonstrate that it is possible to obtain the winning ticket (sub-network and initialization) for a weak version of the lottery ticket hypothesis, based on the fact that the sub-network with initialization can achieve training performance on par with that of the original network using the same number of iterations or fewer.",
  },
  {
    name: "Prof. Robert Kozma",
    pic: k2,
    bio: "(Fellow IEEE; Fellow INNS). He holds a Ph.D. in Applied Physics (DelŌ  University of Technology, The Netherlands), two M.Sc. degrees (MathemaƟcs, Eotvos University, Hungary; and Power Engineering, Moscow, MEI, Russia). He is Professor of MathemaƟcs, funding Director of Center for Large‐Scale Intelligent OpƟmizaƟon and Networks (CLION), FedEx InsƟtute of Technology, University of Memphis, TN. VisiƟng posiƟons include Professor of Computer Science, University of MassachuseƩs Amherst, MA; US Air Force Research Laboratory, Sensors Directorate, WPAFB, OH; NASA Jet Propulsion Laboratory, RoboƟcs Division, Caltech, Pasadena, CA; Sarnoff  Co., Princeton, N.J. Previous affiliaƟons at University of California at Berkeley, EECS and Division of Neurobiology; Otago University, InformaƟon Sciences, New Zealand; Tohoku University, Quantum Science and Engineering, Japan. He has over 35 years of experience in intelligent signal processing, autonomous systems, large‐scale networks and graphs, distributed sensor systems, cogniƟve compuƟng, and biomedical applicaƟons. Published 9 books/edited volumes, about 300 papers, has 3 patents. Gave over 200 presentaƟons at conferences, about half of them are plenary, keynote, and invited talks. Received research funding of over $7M, by agencies NASA, DARPA, AFRL, AFOSR, NSF, ONR, and others. Dr. Kozma has been the President of the InternaƟonal Neural Network Society (2017‐2018), served on the AdCom of the IEEE ComputaƟonal Intelligence Society (2009‐2012), on the Governing Board of IEEE SMC Society (2016‐2018, 2020), and InternaƟonal Neural Network Society (2004‐2012). is presently Editor‐In‐Chief of IEEE TransacƟons of Systems, Man, and CyberneƟcs – Systems. He received various awards, including the INNS Dennis Gabor Award.",
    title:
      "Sustainable Artificial Intelligence ",
    abstract:
      "Cutting‐edge AI and Deep Learning technologies demonstrate outstanding performance in many important tasks requiring intelligent data processing under well‐known conditions, supported by massive computational resources and big data. However, the performance of these systems may drastically deteriorate when the data are perturbed, or the environment dynamically changes, either due to natural effects or caused by manmade disturbances. A neuromorphic perspective provides crucial support under such conditions. Human brains are efficient devices using 20W power (just like a light bulb!), which is drastically less than the power consumption of today’s supercomputers requiring MWs of power to solve specific learning tasks in an innovative way. Analyzing brain energy management helps developing computational and hardware implementations with drastic improvement in using resources, including energy, and provides a path towards sustainable AI. This talk overviews the challenges to intelligent systems, outlines crucial insights from brain studies, and introduces system designs combing the benefits of deep learning and neuromorphic technologies. We introduce several highly competitive solutions with applications to pa􏰂ern recognition, and reinforcement Deep‐Q Learning for interactive decision making and intelligent control. ",
  },
  {
    name: "Prof. Sanghamitra Bandyopadhyay",
    pic: k3,
    bio: " joined the Machine Intelligence Unit of the Indian Statistical Institute as a faculty member in 1999, after completing her PhD from the same Institute. She was the Director of the Institute from August 2015 to July 2020, and is currently on her second tenure as Director from September 2020 onwards. Sanghamitra has worked in various Universities and Institutes world-wide including in USA, Australia, Germany, France, Italy, China, Slovenia and Mexico, and delivered invited lectures in many more countries. She has received several awards and fellowships including the Bhatnagar Prize, Infosys award, TWAS Prize, DBT National Women Bioscientist Award (Young), INAE Silver Jubilee Prize, Young scientist/engineer medals of INSA, INAE and Science Congress, JC Bose Fellowship, Swarnajayanti Fellowship and Humboldt Fellowship. She is a Senior Associate of ICTP and Fellow of INSA, INAE, NASI and IEEE. She is currently a member of the Science, Technology and Innovation Advisory Council of the Prime Minister of India (PM-STIAC). The current research interests of Prof. Sanghamitra Bandyopadhyay include artificial intelligence, machine learning, soft and evolutionary computation, pattern recognition, data mining and various other applications. She has authored/co-authored more than 300 research article in international journals, conferences and book chapters, and published six authored and edited books from publishers like Springer, World Scientific and Wiley. She has also edited journals special issues in the area of soft computing, data mining, and bioinformatics.",
    title:
      "Healthcare and machine learning - a symbiotic relationship",
    abstract:
      "Application of Machine Learning (ML) based approaches in healthcare research is increasing at a rapid pace. All areas of healthcare research, from understanding the individual molecules within a cell and their interactions to analysis of images and electronic health records to predicting disease outcomes and health emergencies, have benefitted from the use of ML methods.  In this talk, we will first introduce the central dogma of molecular biology which is fundamental to the understanding of a large class of machine learning applications in biology. Various kinds of data sets emerging in different areas of healthcare research will be mentioned. A few applications of machine learning methods, including deep learning and graph neural network methods, will be described. These will include applications of classification techniques for molecular target prediction, a graph theoretic method for biomarker identification, metaheuristic for drug design and a graph neural network technique for drug to drug interaction prediction. The talk will conclude with a mention of some issues and challenges in this area.",
  },
];
